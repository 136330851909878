// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-recipe-jsx": () => import("./../../../src/templates/recipe.jsx" /* webpackChunkName: "component---src-templates-recipe-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-sustainability-jsx": () => import("./../../../src/templates/sustainability.jsx" /* webpackChunkName: "component---src-templates-sustainability-jsx" */),
  "component---src-templates-variant-jsx": () => import("./../../../src/templates/variant.jsx" /* webpackChunkName: "component---src-templates-variant-jsx" */)
}

