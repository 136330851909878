import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { generateSlug } from '../helpers/slug'
import lang from '../../static/lang.json'

const LocaleContext = React.createContext({
  locale: null,
})

export const useLangCode = () => {
  const { locale } = React.useContext(LocaleContext)
  return locale
}

export const useLang = () => {
  const { locale } = React.useContext(LocaleContext)
  return (key) => (lang[key] ? lang[key][locale] : '')
}

export const HrefLangMeta = ({
  node_locale: locale,
  product,
  category,
  sub,
  title,
  slug,
}) => {
  if (!locale) return null

  const rootPath = locale.toLowerCase()

  const pathParts = [
    rootPath,
    ...(sub && [lang[sub][rootPath]]),
    ...(category && [category.title]),
    ...(product && [product.title]),
    slug || title,
  ]

  const parts = generateSlug(pathParts.join('/'))
  const url = `/${parts}`

  return (
    <LocaleContext.Consumer>
      {({ sethrefLang }) => {
        sethrefLang(url)
        return (
          <Helmet>
            <link rel="alternate" href={url} hrefLang={locale} />
          </Helmet>
        )
      }}
    </LocaleContext.Consumer>
  )
}

HrefLangMeta.propTypes = {
  title: PropTypes.string.isRequired,
  node_locale: PropTypes.string.isRequired,
  sub: PropTypes.string,
  category: PropTypes.shape({
    title: PropTypes.string,
  }),
  product: PropTypes.shape({
    title: PropTypes.string,
  }),
}

HrefLangMeta.defaultProps = {
  category: null,
  sub: null,
}

export default LocaleContext
