export default {
  accent: '#483912',
  boxShadow: '-1px 3px 11px rgba(21,21,21,0.01)',
  contrast: '#FFF',
  borderRadius: '2px',
  container: '1450px',
  primary: '#ae1918',
  primaryHover: '#8e3039',
  secondary: '#171717',
  success: '#3E9577',
  muted: 'rgb(239, 238, 238)',
  mutedText: '#676464',
  breakpoint: {
    desktop: '1450px',
    laptop: '1224px',
    tablet: '776px',
    phone: '576px',
  },
}
