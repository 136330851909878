exports.generateSlug = (items, prefix = false) => {
    const out = items
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^A-Za-z0-9 /_]/g, ' ')
        .replace(/(\s+)|(-+)/g, '-')
        .replace(/(\/-)|(-\/)/g, '/')
        .replace(/(\/+)/g, '/')
        .toLowerCase();

    return prefix ? `/${out}` : out;
};

exports.extractLocaleRoot = (str) => {
    const directories = str.split(/[//]/);
    return directories[0] || directories[1];
};

exports.capitalizeWord = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
