import { createGlobalStyle } from 'styled-components';
import vars from '../lib/styled';

export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Lato');

    body,
    html { 
        -webkit-font-smoothing: antialiased;
        color: #555;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        width: 100%;

        @media (max-width: ${vars.breakpoint.desktop}){
            font-size: 16px;
        }
    }

    body,
    html,
    article,
    main,
    figure,
    aside,
    section,
    div,
    label, 
    input,
    select,
    footer, 
    header {
        box-sizing: border-box;
        max-width: 100%;
    }

    h1, 
    h2, 
    h3, 
    h4,
    h5, 
    h6 { 
        color: ${vars.secondary};
        font-family: Georgia, Times, serif;
        font-weight: 100; 
        line-height: 1.2;
        letter-spacing: .5px;
        margin: 0 0 1rem 0;
        position: relative;
    }

    h1 {
        font-size: 2.887em;
        @media (max-width: ${vars.breakpoint.phone}){
            font-size: 2.1em;
        }
    }

    h2 {
        font-size: 2.266em;
    }

    h3 {
        font-size: 2.027em;
    }

    .divider {
        background-color: ${vars.accent};
        border: 0;
        display: block;
        margin: 1rem 0;
        height: 2px;
        width: 55px;
    }

    .list-dividers {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            border-bottom: 2px solid ${vars.muted};
            padding: 0.5rem 1rem;
        }
    }

    h4, 
    h5 {
        margin-bottom: 2rem;
        text-transform: capitalize;
    }

    h4 {
        font-size: 1.424em;
    }

    h5 {
        font-size: 1.22em;
    }

    small,
    h6 {
        font-size: 0.833rem;
    }

    small {
        display: inline-block;
        font-weight: 200;
        line-height: 1.5;
    }

    a { 
        color: ${vars.mutedText};
        transition: color 350ms ease-in-out;

        &:hover {
            color: ${vars.accent};
        }
    }

    p, 
    a,
    li {
        font-size: 1em;
        line-height: 1.7;
        letter-spacing: .25px;
    }
 
    li p {
        margin: 0;
    }

    select, 
    textarea, 
    input:not([type=checkbox]),
    input:not([type=radio]),
    input:not([type=file]) { 
        background: transparent;
        border: 0;
        display: block;
        font-family: 'Lato', sans-serif;
        font-size: 1em;
        line-height: 2.5;
        height: 2.5rem;
        outline: 0;
        width: 100%;
    }

    ::-webkit-search-cancel-button {
        cursor: pointer;
        position: relative;
        right: 20px;
    }

    textarea {
        min-height: 250px;
    }

    hr {
        border: 1px solid ${vars.muted};
        margin: 2rem 0;
    }

    iframe {
        max-width: 100%;

        @media (max-width: ${vars.breakpoint.phone}){
            height: auto !important;
        }
    }

    address {
        font-style: normal;
    }

    blockquote {
      font-size: 1rem;
      border-left: 2px solid;
      padding-left: 2rem;
    }

    [data-reach-skip-link] {
      box-sizing: border-box;
      position: absolute;
      width: 0;
      overflow: hidden;
      top: 1rem;
      left: 1rem;
      background: white;
      padding: 0;
      height: 0;
      color: #000;
      

      &:focus {
        height: auto;
        width: auto;
        z-index: 100;
      }
    }
`;
