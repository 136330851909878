import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Theme from './src/components/Theme'
import { extractLocaleRoot } from './src/helpers/slug'
import StyleSheet from './src/components/StyleSheet'
import LocalContext from './src/components/Locale'

const LocaleWrapper = ({ locale, children }) => {
  const [hrefLang, sethrefLang] = useState()

  return (
    <LocalContext.Provider
      value={{
        locale,
        hrefLang,
        sethrefLang,
      }}
    >
      {children}
    </LocalContext.Provider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  const { pathname, href } = props.location
  const locale = extractLocaleRoot(pathname) || 'en-CA'

  return (
    <Theme>
      <LocaleWrapper locale={locale}>
        <StyleSheet />
        <Helmet>
          <html lang={locale} amp />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={href} />
          <script
            async
            src="https://polyfill.io/v3/polyfill.min.js?features=default%2CWeakMap%2CWeakSet%2Cfetch"
          />
          <script
            type="text/javascript"
            async
            defer
            src="//assets.pinterest.com/js/pinit.js"
          />
        </Helmet>
        {element}
      </LocaleWrapper>
    </Theme>
  )
}
