import React from 'react';
import ReactDOM from 'react-dom';
export { wrapPageElement } from './gatsby-ssr';

export const onClientEntry = async () => {
    if (process.env.NODE_ENV === 'development') {
        const { default: axe } = await import('react-axe');
        axe(React, ReactDOM, 1000);
    }
};
